* {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}

a {
  text-decoration: none;
}

#formContainer {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0
}

#box {
  padding: 25px;
  width: 350px;
  top: 20%;
  margin: auto;
  border-radius: 3px;
  background-color: #FFF;
  position: relative;
  display: flex;
  flex-direction: column
}

#bc:hover {
  background-color: #3590F3 !important;
}

#bj {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.c {
  cursor: pointer;
}

.h:hover {
  background-color: #E7F3FF;
  border-radius: 5px;
}

.ha:hover {
  background-color: #FDE9E9;
  border-radius: 5px;
}

.ba {
  display: flex;
  align-items: center;
}

.nh:hover {
  background-color: inherit !important;
}

.bd {
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 235);
  padding: 10px 5px;
}

.bb {
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 0.8px solid #DCDFE6;
}

.bb:hover {
  border-bottom: 1px solid #3590F3;
}

.blockBtn{
  background-color: #FF4B3C !important;
}

.unblockBtn{
  background-color: #54B948 !important;
}