.MuiFab-root {
    margin: 0;
    top: 'auto';
    right: 24px;
    bottom: 32px;
    left: 'auto';
    position: 'fixed';
}

.MuiCheckbox-root:hover {
    background-color: #EBEEF5 !important;
}

.MuiFormControlLabel-label {
    font-size: 14px !important;
}

.MuiToolbar-root {
    padding-left: 0 !important;
}

.MuiContainer-root {
    padding: 0 !important;
}

.css-nes1bo-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled):before,
.css-3hldhk-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before,
.css-z26jq0-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before,
.css-1nvgt24-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before,
.css-i1p4qp-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled):before,
.css-1kql35k-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
    border-color: #FFF !important;
}

.css-a9pfrq-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: #3590F3 !important;
    background-color: #E7F3FF !important;
}

.css-1ww8ika-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled {
    color: #aed5ff !important;
}

.css-zkswlp-MuiPaper-root-MuiAlert-root, .css-1vnyk06-MuiPaper-root-MuiAlert-root {
    color: #5F2120 !important;
    background-color: #FDE9E9 !important
}

.css-18n01ut-MuiPaper-root-MuiAlert-root {
    color: #424345 !important
}

.css-1xskglu-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    background-color: #c3def8 !important;
}

.css-1kql35k-MuiInputBase-root-MuiInput-root {
    border: none !important
}

.css-dhopo2 {
    min-height: 200px !important;
}
.css-1ytlwq5-MuiAlert-icon {
    margin: 2px 15px 2px 8px;
}

.css-acap47-MuiAlert-message{
    font-size: 1rem;
}
.css-4qgm99-MuiPaper-root-MuiAlert-root {
    background: #fff0e9 !important;
}